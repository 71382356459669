<template>
  <!-- <b-card>
      <b-row> -->
  <!-- <b-col md="4">
          <b-form-group label="Company">
            <v-select
              v-model="selected"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="option"
            /> </b-form-group
        ></b-col> -->
  <!-- <b-col md="4">
          <b-form-group label="City">
            <v-select
              v-model="selected"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="option"
            /> </b-form-group
        ></b-col> -->
  <!-- <b-col md="4"
          ><b-form-group label="Production">
            <v-select
              v-model="selected"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="option"
            />
          </b-form-group>
        </b-col> -->
  <!-- </b-row> -->
  <!-- </b-card> -->

  <div>
    <div class="row match-height mb-1" v-for="info in data" :key="info.id">
      <div class="col-md-3">
        <div
          class="
            card card-congratulations
            d-flex
            align-items-center
            justify-content-center
          "
        >
          <div class="text-center py-1">
            <feather-icon icon="UserIcon" size="30" class="mr-1 mb-1" />
            <h4 class="font-weight-bolder text-white mb-1">
              {{ info.company_name || "NA" }}
            </h4>
          </div>
        </div>
      </div>
      <div class="col-lg-2">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <h3 class="fw-bolder mb-75">
                  {{ info.hatcheryCount || "NA" }}
                </h3>
                <p class="card-text">Total Hatchery</p>
              </div>
              <div class="avatar avatar-stats p-50 m-0 bg-light-primary">
                <div class="avatar-content">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-2">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <h3 class="fw-bolder mb-75">{{ info.setterCount || "NA" }}</h3>
                <p class="card-text">Setter</p>
              </div>
              <div class="avatar avatar-stats p-50 m-0 bg-light-danger">
                <div class="avatar-content">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                    <circle cx="8.5" cy="7" r="4"></circle>
                    <line x1="20" y1="8" x2="20" y2="14"></line>
                    <line x1="23" y1="11" x2="17" y2="11"></line>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-2">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <h3 class="fw-bolder mb-75">{{ info.hatcherCount || "NA" }}</h3>
                <p class="card-text">Hatcher</p>
              </div>
              <div class="avatar avatar-stats p-50 m-0 bg-light-success">
                <div class="avatar-content">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                    <circle cx="8.5" cy="7" r="4"></circle>
                    <polyline points="17 11 19 13 23 9"></polyline>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-3">
        <b-card class="d-flex flex-column align-items-center">
          <h6 class="mb-1">Average Production</h6>
          <h2 class="text-green text-center">
            {{ info.avg_production_percentage || "NA" }}%
          </h2>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BCarousel,
  BCarouselSlide,
  BButton,
  BImg,
  BFormDatepicker,
  BFormGroup,
} from "bootstrap-vue";

import vSelect from "vue-select";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BButton,

    BFormDatepicker,

    BCarousel,
    BCarouselSlide,

    BImg,
    BFormGroup,
    vSelect,
  },
  data() {
    return {
      data: {},
    };
  },
  created() {
    this.$http.get(`web/super-admin-dashboard`).then((response) => {
      this.data = response.data;
    });
  },
};
</script>
<style lang="scss" >
.carousel-inner {
  height: 200px !important;
}
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/pages/hatchery.scss";
@import "@core/scss/vue/pages/_common.scss";
.text-green {
  color: #38c06c !important;
}
</style>
